





















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ReserveList extends Vue {
}
