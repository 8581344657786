
































import { Component, Vue } from 'vue-property-decorator';
import Navigation from './Navigation.vue';
import HeaderNavigation from './HeaderNavigation.vue';

@Component({
  components: {
    Navigation,
    HeaderNavigation,
  },
})
export default class Header extends Vue {
    private isStuck = false;

    private mobileNavActive = false;

    /* Configuration Options */
    private gradient = false;

    private fixed = false;

    private stickyNav = true;

    private searchToggle = false;

    private eventListenerOpts: AddEventListenerOptions | EventListenerOptions = { passive: true };

    mounted() {
      this.checkScroll();

      this.$router.afterEach(() => {
        this.mobileNavActive = false;
        this.searchToggle = false;
      });
    }

    checkScroll(): void {
      this.isStuck = window.scrollY > 0;
    }

    created() {
      window.addEventListener('scroll', this.checkScroll, this.eventListenerOpts);
    }

    beforeDestroy() {
      window.removeEventListener('scroll', this.checkScroll, this.eventListenerOpts);
    }

    menuToggle() {
      this.mobileNavActive = !this.mobileNavActive;
      this.searchToggle = !this.searchToggle;
    }
}
