


























import { Component, Vue, Prop } from 'vue-property-decorator';

import VueAgile from 'client-website-ts-library/components/VueAgile/Agile.vue';
import { CarouselBreakpoint } from '../../app_code/CarouselBreakpoint';

@Component({
  components: {
    VueAgile,
  },
})
export default class Cycler extends Vue {
  @Prop()
  private readonly darken!: boolean;

  @Prop({ required: true })
  private readonly items!: number;

  @Prop()
  private readonly autoAdvance!: boolean;

  @Prop({ default: 5000 })
  private readonly autoAdvanceInterval!: number;

  @Prop({ default: 2500 })
  private readonly speed!: number;

  @Prop({ default: true })
  private readonly loop!: boolean;

  @Prop()
  private readonly hideNav!: boolean;

  @Prop({ default: false })
  private readonly hideAgileNav!: boolean;

  @Prop()
  private readonly center!: boolean;

  @Prop({ default: [] })
  private readonly responsive!: CarouselBreakpoint[];

  @Prop({ default: '' })
  private readonly href!: string;

  @Prop({ default: '' })
  private readonly id!: string;

  private settings: object = {
    autoplay: this.autoAdvance,
    autoplaySpeed: this.autoAdvanceInterval,
    centerMode: this.center,
    slidesToShow: this.items,
    responsive: this.responsive,
    navButtons: !this.hideNav,
    loop: this.loop,
    variableWidth: true,
  };

  handleChanged(data: any): void {
    this.$emit('update', {
      index: data.currentSlide,
    });
  }

  goToIndex(index: number): void {
    (this.$refs.cycler as any).goTo(index);
  }

  mounted() {
    if (this.href !== '') {
      const parent = document.getElementById(this.id);
      const get = parent!.querySelector('.agile__actions');
      const node = document.createElement('a');
      node.id = 'viewAllProperties';
      node.innerHTML = 'View all properties';
      node.href = this.href;
      get!.appendChild(node);
    }
  }
}
