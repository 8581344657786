






import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Row extends Vue {
  @Prop({ default: 'var(--gutter)' })
  private readonly gutter!: number;

  @Prop({ default: 'center' })
  private readonly verticalAlign!: string;

  @Prop({ default: 'space-between' })
  private readonly horizontalAlign!: string;

  @Prop({ default: 'wrap' })
  private readonly wrap!: string;
}
