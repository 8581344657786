






















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import MultiSelect, { MultiSelectOption } from 'client-website-ts-library/components/MultiSelect.vue';
import RangeSlider from 'client-website-ts-library/components/RangeSlider.vue';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingCategory, ListingStatus, PropertyType } from 'client-website-ts-library/types';
import { API, Logger, LogLevel } from 'client-website-ts-library/services';
import ToggleButton from './ToggleButton.vue';

@Component({
  components: {
    MultiSelect,
    RangeSlider,
    ToggleButton,
  },
})
export default class ListingSearch extends Vue {
  @Prop({ default: 'Buy' })
  private readonly methodOfSale!: string;

  @Prop({ default: '900px' })
  private readonly maxWidth!: string;

  @Prop({ default: true })
  private readonly showPropertyTypes!: boolean;

  private mos: string = this.methodOfSale;

  private filter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Current,
      ListingStatus.UnderContract,
    ],
    PageSize: 9,
  });

  private propertyTypeOptions: MultiSelectOption[] = [
    { Value: PropertyType.House.toString(), Label: 'House' },
    { Value: PropertyType.Apartment.toString(), Label: 'Apartment' },
    { Value: PropertyType.Unit.toString(), Label: 'Unit' },
    { Value: PropertyType.Townhouse.toString(), Label: 'Townhouse' },
    { Value: PropertyType.Villa.toString(), Label: 'Villa' },
    { Value: PropertyType.BlockOfUnits.toString(), Label: 'Block Of Units' },
    { Value: PropertyType.Studio.toString(), Label: 'Studio' },
    { Value: PropertyType.Retirement.toString(), Label: 'Retirement Living' },
    { Value: PropertyType.Acreage.toString(), Label: 'Acreage' },
    { Value: 'Land', Label: 'Land' },
  ];

  private salePriceRange: number[] = [];

  private leasePriceRange: number[] = [];

  private landSizeRange: number[] = [];

  private keywordOptions: MultiSelectOption[] = [];

  private selectedKeywords: MultiSelectOption[] = [];

  private beds = 0;

  private baths = 0;

  private hasLand = false;

  private formatCurrency = (val: number): string => `$${val.toLocaleString('en-AU')}`;

  mounted() {
    this.updateFilter();
  }

  @Watch('methodOfSale')
  updateMos() {
    this.mos = this.methodOfSale;
  }

  private updateSuburbsDebounce: number | undefined = undefined;

  updateSuburbs() {
    clearTimeout(this.updateSuburbsDebounce);

    this.updateSuburbsDebounce = window.setTimeout(() => {
      const tmpFilter = new ListingFilter(this.filter);

      tmpFilter.Suburbs = [];

      API.Listings.GetSuburbs(tmpFilter).then((keywordOptions) => {
        const opts = keywordOptions.map((suburb) => ({ Value: `suburb:${suburb}`, Label: suburb.toUpperCase() }));

        /* // ADD OPTIONS
        opts.push(...[
          {
            Value: 'categories:8',
            Label: 'Industrial',
          },
          {
            Value: 'categories:12',
            Label: 'Offices',
          },
          {
            Value: 'categories:15',
            Label: 'Retail',
          },
          {
            Value: 'categories:9',
            Label: 'Land Development',
          },
          {
            Value: 'categories:11',
            Label: 'Medical Consulting',
          },
        ]);
        */

        this.keywordOptions = opts;
      });
    }, 2000);
  }

  updatePropertyTypes() {
    try {
      if (this.$route.query.propertyTypes) {
        const propertyTypes = (this.$route.query.propertyTypes as string).split('|').join(',');

        const selectedFilterTypes: PropertyType[] = [];
        const selectedDropdownTypes: string[] = [];

        this.propertyTypeOptions.forEach((typeOption) => {
          if (propertyTypes.indexOf(typeOption.Value) !== -1) {
            selectedFilterTypes.push(...typeOption.Value.split(',').map((p) => parseInt(p, 10) as PropertyType));
            selectedDropdownTypes.push(typeOption.Value);
          }
        });

        this.hasLand = selectedDropdownTypes.some((s) => s === 'Land') ?? false;
        this.filter!.PropertyTypes = selectedFilterTypes;
        (this.$refs.PropertyTypeMultiSelect as MultiSelect).setValues(selectedDropdownTypes);
      }
    } catch (ex) {
      Logger.Log(LogLevel.Warning, '[ListingMap]', 'Failed to parse property type multi select from query');
    }
  }

  @Watch('mos')
  @Watch('beds')
  @Watch('baths')
  updateFilter() {
    const opts = this.selectedKeywords.map((s) => {
      const split = s.Value.split(':');

      return {
        Type: split[0],
        Value: split[1],
      };
    });

    switch (this.mos) {
      case 'BUY':
        this.filter.Categories = [
          ListingCategory.ResidentialSale,
          ListingCategory.ResidentialLand,
          ListingCategory.Rural,
        ];
        break;
      case 'RENT':
        this.filter.Categories = [
          ListingCategory.ResidentialRental,
        ];
        break;
      default:
        this.filter.Categories = [];
        break;
    }

    this.updateSuburbs();
    this.updatePropertyTypes();

    this.filter.MinBedrooms = this.beds;
    this.filter.MinBathrooms = this.baths;

    this.filter.PropertyCategories = opts.filter((s) => s.Type === 'categories').map((s) => parseInt(s.Value, 10));
    this.filter.Suburbs = opts.filter((s) => s.Type === 'suburb').map((s) => s.Value);

    const customOpt = opts.find((s) => s.Type === 'search');

    if (customOpt) {
      this.filter.SearchTerm = customOpt.Value;
    } else {
      this.filter.SearchTerm = undefined;
    }

    this.filter.Page = 1;

    this.emitFilter();
  }

  suburbsUpdated(selectedKeywords: MultiSelectOption[]) {
    this.selectedKeywords = selectedKeywords;

    this.updateFilter();
  }

  propertyTypesUpdated(selectedPropertyTypes: MultiSelectOption[]) {
    if (!this.filter) return;

    this.filter.PropertyTypes = [];

    for (let i = 0; i < selectedPropertyTypes.length; i += 1) {
      if (selectedPropertyTypes[i].Value !== 'Land') {
        // eslint-disable-next-line radix
        const types = selectedPropertyTypes[i].Value.split(',').map((v: string) => parseInt(v));
        this.filter.PropertyTypes.push(...types);
      }
    }

    this.hasLand = selectedPropertyTypes.some((s) => s.Value === 'Land') ?? false;

    // this.doSearch();
  }

  private emitDebounce: number | undefined = undefined;

  emitFilter() {
    this.$emit('filter_updated', this.filter);
  }

  doSearch() {
    const params: Record<string, string> = {};

    /* if (this.landSizeRange.length === 2) {
      params.areaMin = this.landSizeRange[0].toString();
      params.areaMax = this.landSizeRange[1].toString();
    } */

    if (this.selectedKeywords.length) {
      params.keywordOptions = this.selectedKeywords.map((s) => s.Value).join('|');
    }

    if (this.filter.PropertyTypes.length > 0) {
      params.propertyTypes = this.filter.PropertyTypes.join('|') + (this.hasLand ? '|Land' : '');
    } else {
      params.propertyTypes = (this.hasLand ? 'Land' : '');
    }

    switch (this.mos) {
      case 'RENT':
        // eslint-disable-next-line no-lone-blocks
        {
          if (this.leasePriceRange.length === 2) {
            params.priceMin = this.leasePriceRange[0].toString();
            params.priceMax = this.leasePriceRange[1].toString();
          }

          this.$router.push({
            path: '/property/for-rent',
            query: params,
          });
        }
        break;
      case 'SOLD':
        // eslint-disable-next-line no-lone-blocks
        {
          if (this.salePriceRange.length === 2) {
            params.priceMin = this.salePriceRange[0].toString();
            params.priceMax = this.salePriceRange[1].toString();
          }

          this.$router.push({
            path: '/property/sold',
            query: params,
          });
        }
        break;
      case 'BUY':
      default:
        // eslint-disable-next-line no-lone-blocks
        {
          if (this.salePriceRange.length === 2) {
            params.priceMin = this.salePriceRange[0].toString();
            params.priceMax = this.salePriceRange[1].toString();
          }

          this.$router.push({
            path: '/property/for-sale',
            query: params,
          });
        }
        break;
    }
  }
}
