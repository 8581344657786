











import { Component, Vue, Prop } from 'vue-property-decorator';
import { Blog } from 'client-website-ts-library/types';

@Component
export default class BlogCard extends Vue {
  @Prop()
  private readonly blog!: Blog;

  mounted() {
    console.log(this.blog);
  }
}
