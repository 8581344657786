import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  // BUY
  {
    path: '/property/for-sale/',
    name: 'Properties For Sale in Cairns',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/open-homes',
    name: 'Open Homes',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/buying-tips',
    name: 'Buying Tips',
    component: () => import('../views/BuyingTips.vue'),
  },
  {
    path: '/forms/sales-appraisal/',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  // RENT
  {
    path: '/property/for-rent/',
    name: 'Properties For Rent in Cairns',
    component: () => import('../views/PropertiesForRent.vue'),
  },
  {
    path: '/why-rent-with-us',
    name: 'Why Rent With Us',
    component: () => import('../views/WhyRentWithUs.vue'),
  },
  {
    path: '/why-switch',
    name: 'Why Switch',
    component: () => import('../views/WhySwitch.vue'),
  },
  {
    path: '/forms/rental-assessment',
    name: 'Rental Assessment',
    component: () => import('../views/RentalAssessment.vue'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance Request',
    component: () => import('../views/MaintenanceRequest.vue'),
  },
  {
    path: '/notice-of-leave',
    name: 'Notice Of Leave',
    component: () => import('../views/NoticeOfLeave.vue'),
  },
  {
    path: '/tenancy-application',
    name: 'Tenancy Application',
    component: () => import('../views/TenancyApplicationForm.vue'),
  },
  // SELL
  {
    path: '/why-sell-with-us',
    name: 'Why Sell With Us',
    component: () => import('../views/WhySellWithUs.vue'),
  },
  {
    path: '/property/sold/',
    name: 'Properties Sold in Cairns',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  /* {
    path: '/vendor-discount',
    name: 'Vendor Discount',
    component: () => import('../views/VendorDiscount.vue'),
  }, */
  // ESTATES
  {
    path: '/current-projects',
    name: 'Current Projects',
    component: () => import('../views/LandEstates.vue'),
  },
  // BLOG
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog Post',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/estates/:id',
    name: 'Estate',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  // ABOUT
  {
    path: '/about-us',
    name: 'About Us',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/why-us',
    name: 'Why Us',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/our-team',
    name: 'Our Team',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/Contact.vue'),
  },
  // OTHER
  {
    path: '/blogs',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  // Dynamic Pages
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/people/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/listings/:id/make-an-offer',
    name: 'Make an Offer',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/make-an-offer/:id',
    name: 'Make an Offer',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/people/:id/properties/sold',
    name: 'Profile Properties Sold',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/people/:id/properties',
    name: 'Profile Properties',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  // Other
  {
    path: '/thank-you',
    name: 'Thank You',
    component: () => import('../views/ThankYou.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    meta: {
      hide: true,
    },
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/tv-sales',
    name: 'TV Display Sales',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/TVDisplaySales.vue'),
  },
  {
    path: '/tv-portrait',
    name: 'TV Display',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/TVDisplayPortrait.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        API.Slugs.Resolve(to.fullPath.split(' ').join('-')).then((slug) => {
          switch (slug.Type) {
            case 'Listing':
              next({
                path: `/listings/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Profile':
              next({
                path: `/people/${slug.Slug}`,
                replace: true,
              });
              break;
            case 'Office':
              API.Offices.Get(slug.Id).then((office) => {
                next({
                  path: `/offices/${office.OfficeSlug}`,
                  replace: true,
                });
              }).catch(() => {
                next();
              });
              break;
            default:
              next();
              break;
          }
        }).catch(() => {
          next();
        });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from && from.path === to.path) return null;

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
