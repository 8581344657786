





























import { Component, Vue, Prop } from 'vue-property-decorator';
import Masthead from './Common/Masthead.vue';
import ListingSearch from './ListingSearch.vue';

@Component({
  components: {
    Masthead,
    ListingSearch,
  },
})
export default class SiteMasthead extends Vue {
  @Prop()
  private readonly mini!: boolean;

  @Prop()
  private readonly title!: string;

  @Prop()
  private readonly subtitle!: string;

  @Prop({ default: { source: '/assets/images/default/masthead', formats: ['avif', 'webp', 'jpg'], fallback: 'jpg' } })
  private readonly image!: string;

  @Prop({ default: true })
  private readonly darken!: boolean;

  @Prop({ default: false })
  private readonly extraDark!: boolean;

  @Prop()
  private readonly height!: string;
}
