














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { API, Config } from 'client-website-ts-library/services';

import { CarouselBreakpoint } from '@/app_code/CarouselBreakpoint';

import { BlogFilter } from 'client-website-ts-library/filters';
import { Blog, BlogType, WebsiteLevel } from 'client-website-ts-library/types';

import BlogCard from '../BlogCard.vue';
import Blogs from '../Blogs.vue';
import Cycler from './Cycler.vue';
import CyclerItem from './CyclerItem.vue';

@Component({
  components: {
    Cycler,
    CyclerItem,
    BlogCard,
    Blogs,
  },
})
export default class BlogCycler extends Vue {
  private blogs: Blog[] = [];

  private breakpoints: CarouselBreakpoint[] = [
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 3,
      },
    },
  ];

  @Prop()
  private filter = new BlogFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.API.WebsiteId,
    Types: [BlogType.Social, BlogType.Suburb, BlogType.Promotions, BlogType.Market, BlogType.Invest, BlogType.Landlord],
  });

  @Prop({ default: false })
  private isMobile!: boolean;

  mounted() {
    this.updateTestimonials();
    this.onResize();
    window.addEventListener('resize', this.onResize);
  }

  onResize() {
    this.isMobile = (window.innerWidth < 1001);
  }

  @Watch('filter', { deep: true })
  updateTestimonials() {
    API.Blogs.Search(this.filter, true).then((blogs) => {
      this.blogs = blogs;

      this.$emit('got_blog_count', blogs.length);

      this.$emit('got_blogs', blogs);
    });
  }
}
